import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertsComponent } from './alerts.component';

const routes: Routes = [
  {
    path: '',
    component: AlertsComponent,
    children: [
      {
        path: 'visor-alertas',
        loadChildren: () =>
          import('./alerts-viewer/alerts-viewer.module').then((m) => m.AlertsViewerModule),
      },
    ],
  },
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlertsRoutingModule { }
