import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componentes
import { AlertsComponent } from './alerts.component';

// Internal modules
import { AlertsRoutingModule } from './alerts-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';


@NgModule({
  declarations: [
    AlertsComponent
  ],
  imports: [
    CommonModule,

    //Internal Modules
    AlertsRoutingModule,
    SharedModule,
    StaticModule
  ]
})
export class AlertsModule { }
