import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-alerts',
  templateUrl: './alerts.component.html',
  styles: [
  ]
})
export class AlertsComponent {

}
